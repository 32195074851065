:root {
    /* ### Colours ### */

    /* # General # */
    --black: #111111 !important;
    --navbar-opacity-black: rgba(0, 0, 0, 0.85) !important;

    /* # Containers # */
    --under-construction-bg: #384861;
    /* ### Font Sizes ### */
    
    /* # Mobile [ < 768px ] # */
    --xs-font-maintitle: 92px !important;
    --xs-font-subtitle: 22px !important;
    --xs-font-category: 28px !important;
    --xs-font-regulartext: 16px !important; /* Also used for Buttons */
    --xs-font-smalltitle: 16px !important;
    --xs-font-smalltext: 16px !important;
    --xs-font-currency: 22px !important;

    /* # Ipad [ >= 768px ] # */
    --sm-font-maintitle: 92px !important;
    --sm-font-subtitle: 22px !important;
    --sm-font-category: 32px !important;
    --sm-font-regulartext: 18px !important; /* Also used for Buttons */
    --sm-font-smalltitle: 18px !important;
    --sm-font-smalltext: 18px !important;
    --sm-font-currency: 24px !important;

    /* # Laptop [ >= 992px ] # */
    --md-font-maintitle: 72px !important;
    --md-font-subtitle: 16px !important;
    --md-font-category: 28px !important;
    --md-font-regulartext: 16px !important; /* Also used for Buttons */
    --md-font-smalltitle: 16px !important;
    --md-font-smalltext: 16px !important;
    --md-font-currency: 24px !important;

    /* # Desktop [ >= 1200px ] # */
    --lg-font-maintitle: 98px !important;
    --lg-font-subtitle: 20px !important;
    --lg-font-category: 38px !important;
    --lg-font-regulartext: 18px !important; /* Also used for Buttons */
    --lg-font-smalltitle: 16px !important;
    --lg-font-smalltext: 16px !important;
    --lg-font-currency: 28px !important;

     /* # Retina [ >= 1400px ] # */
     --xl-font-maintitle: 98px !important;
     --xl-font-subtitle: 20px !important;
     --xl-font-category: 38px !important;
     --xl-font-regulartext: 20px !important; /* Also used for Buttons */
     --xl-font-smalltitle: 16px !important;
     --xl-font-smalltext: 16px !important;
     --xl-font-currency: 28px !important;

    /* ### END ### */
}
