@import 'root.css';
@import 'fonts.css';

.conDiv {
    margin-top: 4em;
}

.conMainTitle {
    text-align: center;
    font-size: 10vw;
    font-weight: 400;
}

.conSubTitle {
    font-size: 5vw;
    font-weight: 200;
}

.conMainTitleAlt {
    color: bisque;
}

.conMinor {
    
    font-weight: 200;
}

/* # Laptop [ >= 992px ] # */
@media only screen and (min-width: 992px) {

    .conDiv {
        margin-top: 4em;
    }
    
    .conMainTitle {
        font-size: 5vw;
        font-weight: 400;
    }
    
    .conSubTitle {
        font-size: 2.5vw;
        font-weight: 200;
    }
    
    .conMinor {
        font-weight: 200;
    }

}