@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700;800&display=swap');
@import 'root.css';

.maintitle, .subtitle, .category, .regulartext, .smalltitle, .smalltext, .currency {
    font-family: 'Raleway', sans-serif;
    color: var(--primary-text);
}

/* # Mobile [ < 768px ] # */
.maintitle{ font-size: var(--xs-font-maintitle); font-weight:600; }
.subtitle{ font-size: var(--xs-font-subtitle); font-weight:400; font-style: italic; }
.category{ font-size: var(--xs-font-category); font-weight:400; font-style: normal; }    
.regulartext{ font-size: var(--xs-font-regulartext); font-weight:400; font-style: normal; } /* Also used for Buttons */
.smalltitle{ font-size: var(--xs-font-smalltitle); font-weight:600; font-style: normal; }
.smalltext{ font-size: var(--xs-font-smalltext); font-weight:400; font-style: normal; }
.currency{ font-size: var(--xs-font-currency); font-weight:600; font-style: normal; }

/* # Ipad [ >= 768px ] # */
@media only screen and (min-width: 768px) {
    .maintitle{ font-size: var(--sm-font-maintitle); }
    .subtitle{ font-size: var(--sm-font-subtitle); }
    .category{ font-size: var(--sm-font-category); }    
    .regulartext{ font-size: var(--sm-font-regulartext); } /* Also used for Buttons */
    .smalltitle{ font-size: var(--sm-font-smalltitle); }
    .smalltext{ font-size: var(--sm-font-smalltext); }
    .currency{ font-size: var(--sm-font-currency); }
}

/* # Laptop [ >= 992px ] # */
@media only screen and (min-width: 992px) {
    .maintitle{ font-size: var(--md-font-maintitle); text-align: left;}
    .subtitle{ font-size: var(--md-font-subtitle); text-align: left;}
    .category{ font-size: var(--md-font-category); }    
    .regulartext{ font-size: var(--md-font-regulartext); } /* Also used for Buttons */
    .smalltitle{ font-size: var(--md-font-smalltitle); }
    .smalltext{ font-size: var(--md-font-smalltext); }
    .currency{ font-size: var(--md-font-currency); }
}

/* # Desktop [ >= 1200px ] # */
@media only screen and (min-width: 1200px) {
    .maintitle{ font-size: var(--lg-font-maintitle); }
    .subtitle{ font-size: var(--lg-font-subtitle); }
    .category{ font-size: var(--lg-font-category); }    
    .regulartext{ font-size: var(--lg-font-regulartext); } /* Also used for Buttons */
    .smalltitle{ font-size: var(--lg-font-smalltitle); }
    .smalltext{ font-size: var(--lg-font-smalltext); }
    .currency{ font-size: var(--lg-font-currency); }
}

@media only screen and (min-width: 1400px) {
    .maintitle{ font-size: var(--xl-font-maintitle); }
    .subtitle{ font-size: var(--xl-font-subtitle); }
    .category{ font-size: var(--xl-font-category); }    
    .regulartext{ font-size: var(--xl-font-regulartext); } /* Also used for Buttons */
    .smalltitle{ font-size: var(--xl-font-smalltitle); }
    .smalltext{ font-size: var(--xl-font-smalltext); }
    .currency{ font-size: var(--xl-font-currency); }
}