@import 'root.css';
@import 'fonts.css';

.App {
  text-align: center;
}

.App-header {
  background-image: url('../images/under-construction.png');
  background-size: cover;
  background-color: var(--under-construction-bg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}
